import { HOME } from 'constants/Routes';
import * as routes from 'constants/Routes';
import { RiOrganizationChart } from 'react-icons/ri';
import { FaBook, FaUsersCog, FaUserTie } from 'react-icons/fa';
import { IoCalendarOutline } from 'react-icons/io5';
import { HiClipboardDocumentList } from 'react-icons/hi2';
import { TbReportAnalytics } from 'react-icons/tb';
import { MdOutlineCelebration, MdSummarize } from 'react-icons/md';
import { AiFillSetting, AiFillDatabase } from 'react-icons/ai';
import { TbLockAccess } from 'react-icons/tb';
import { SiGoogleanalytics } from 'react-icons/si';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { IoIosPeople } from 'react-icons/io';
import { BiTimer } from 'react-icons/bi';

//array for sidebar
const Columns = [
  {
    name: 'Dashboard',
    icon: <SiGoogleanalytics className='text-[#1E3050] w-4 h-4' />,
    to: HOME,
  },

  {
    name: 'Staffs',
    icon: <FaUserTie className='text-[#1E3050] w-4 h-4' />,

    to: routes.STAFF,
    policy: 'staff.index',
  },

  {
    name: 'Attendance',
    icon: <HiClipboardDocumentList className='text-[#1E3050] w-4 h-4' />,
    to: routes.ATTENDANCES,
    policy: 'attendance.index',
  },

  {
    name: 'Leaves',
    icon: <IoCalendarOutline className='text-[#1E3050] w-4 h-4' />,
    to: routes.LEAVE,
    policy: 'leave.index',
  },

  {
    name: 'Events',
    icon: <MdOutlineCelebration className='text-[#1E3050] w-4 h-4' />,
    to: routes.EVENTS,
    policy: 'event.index',
  },

  {
    name: 'Organization Tree',
    icon: <RiOrganizationChart className='text-[#1E3050] w-4 h-4' />,
    to: routes.ORGANIZATION,
    policy: 'organization.index',
  },

  {
    name: 'Reports',
    icon: <TbReportAnalytics className='text-[#1E3050] w-4 h-4' />,
    policy: 'report.index',
    subMenu: [
      {
        title: 'Leave',
        icon: <IoCalendarOutline className='text-[#1E3050] w-4 h-4' />,
        to: routes.LEAVE_REPORTS,
        policy: 'reports.leave',
      },
      {
        title: 'Leave Summary',
        icon: <MdSummarize className='text-[#1E3050] w-4 h-4' />,
        to: routes.LEAVE_SUMMARY_REPORTS,
        policy: 'reports.leave-summary',
      },
      {
        title: 'Staff',
        icon: <IoIosPeople className='text-[#1E3050] w-4 h-4' />,
        to: routes.STAFF_REPORTS,
        policy: 'reports.staff',
      },
      {
        title: 'Working Time',
        icon: <BiTimer className='text-[#1E3050] w-4 h-4' />,
        to: routes.WORKING_TIME_REPORTS,
        policy: 'reports.working-time',
      },
      {
        title: 'Attendance',
        icon: <HiClipboardDocumentList className='text-[#1E3050] w-4 h-4' />,
        to: routes.ATTENDANCE_REPORTS,
        policy: 'reports.attendance',
      },
    ],
  },

  {
    name: 'SMS',
    icon: <HiOutlineChatAlt2 className='text-[#1E3050] w-4 h-4' />,
    to: routes.SMS,
    policy: 'sms.index',
  },

  {
    name: 'Settings',
    icon: <AiFillSetting className='text-[#1E3050] w-4 h-4' />,
    policy: 'setting.index',
    subMenu: [
      {
        title: 'Users',
        icon: <FaUsersCog className='text-[#1E3050] w-4 h-4' />,
        to: routes.USER,
        policy: 'user.index',
      },
      {
        title: 'Roles',
        icon: <TbLockAccess className='text-[#1E3050] w-4 h-4' />,
        to: routes.ROLES,
        policy: 'role.index',
      },

      {
        title: 'Master Data',
        icon: <AiFillDatabase className='text-[#1E3050] w-4 h-4' />,
        to: routes.MASTER_DATA,
        policy: 'masterdata.index',
      },

      {
        title: 'Audit Logs',
        icon: <FaBook className='text-[#1E3050] w-4 h-4' />,
        to: routes.AUDIT_LOGS,
        policy: 'audit.index',
      },
    ],
  },
];
export default Columns;
