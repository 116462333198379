export const HOME = '/';
export const LOGIN = '/login';
export const OTP = '/otp';

//staff
export const STAFF = '/staffs';
export const STAFF_CREATE = '/staffs/create';
export const STAFF_VIEW = '/staffs/:id';
export const STAFF_EDIT = '/staffs/:id/edit';

export const AUDIT_LOGS = '/audit-logs';

//setting
export const MASTER_DATA = '/master-data';

//organization
export const ORGANIZATION = '/organization-tree';

//attendance
export const ATTENDANCES = '/attendances';

//events
export const EVENTS = '/events';
export const EVENTS_CREATE = '/events/create';
export const EVENTS_VIEW = '/events/:id';
export const EVENTS_EDIT = '/events/:id/edit';

//leaves
export const LEAVE = '/leaves';
export const LEAVE_CREATE = '/leaves/create';
export const LEAVE_VIEW = '/leaves/:id';
export const LEAVE_EDIT = '/leaves/:id/edit';

//holidays
export const HOLIDAYS = '/holidays';

//user
export const USER = '/settings/users';

//sms

export const REPORTS = '/reports';
export const LEAVE_REPORT = '/leave-report/:id';
export const LEAVE_APPROVAL = '/leave/:id/:start/:end';

export const ROLES = '/roles';
export const SMS = '/sms';
export const SMS_TEMPLATE = '/sms/template';

export const LEAVE_REPORTS = '/reports/leave';
export const STAFF_REPORTS = '/reports/staff';
export const LEAVE_SUMMARY_REPORTS = '/reports/leave-summary';
export const WORKING_TIME_REPORTS = '/reports/working-time';
export const ATTENDANCE_REPORTS = '/reports/attendance';
