//title
export const LOAD_TITLE_REQUEST = 'LOAD_TITLE_REQUEST';
export const LOAD_TITLE_SUCCESS = 'LOAD_TITLE_SUCCESS';
export const LOAD_TITLE_FAIL = 'LOAD_TITLE_FAIL';

//ethnicity

export const LOAD_ETHNICITY_REQUEST = 'LOAD_ETHNICITY_REQUEST';
export const LOAD_ETHNICITY_SUCCESS = 'LOAD_ETHNICITY_SUCCESS';
export const LOAD_ETHNICITY_FAIL = 'LOAD_ETHNICITY_FAIL';

//religion
export const LOAD_RELIGION_REQUEST = 'LOAD_RELIGION_REQUEST';
export const LOAD_RELIGION_SUCCESS = 'LOAD_RELIGION_SUCCESS';
export const LOAD_RELIGION_FAIL = 'LOAD_RELIGION_FAIL';

//subject
export const LOAD_SUBJECT_REQUEST = 'LOAD_SUBJECT_REQUEST';
export const LOAD_SUBJECT_SUCCESS = 'LOAD_SUBJECT_SUCCESS';
export const LOAD_SUBJECT_FAIL = 'LOAD_SUBJECT_FAIL';

//marital status
export const LOAD_MARITAL_STATUS_REQUEST = 'LOAD_MARITAL_STATUS_REQUEST';
export const LOAD_MARITAL_STATUS_SUCCESS = 'LOAD_MARITAL_STATUST_SUCCESS';
export const LOAD_MARITAL_STATUS_FAIL = 'LOAD_MARITAL_STATUS_FAIL';

//position
export const LOAD_POSITION_REQUEST = 'LOAD_POSITION_REQUEST';
export const LOAD_POSITION_SUCCESS = 'LOAD_POSITION_SUCCESS';
export const LOAD_POSITION_FAIL = 'LOAD_POSITION_FAIL';

//salary method
export const LOAD_SALARY_METHOD_REQUEST = 'LOAD_SALARY_METHOD_REQUEST';
export const LOAD_SALARY_METHOD_SUCCESS = 'LOAD_SALARY_METHOD_SUCCESS';
export const LOAD_SALARY_METHOD_FAIL = 'LOAD_SALARY_METHOD_FAIL';

//Serving as
export const LOAD_SERVING_AS_REQUEST = 'LOAD_SERVING_AS_REQUEST';
export const LOAD_SERVING_AS_SUCCESS = 'LOAD_SERVING_AS_SUCCESS';
export const LOAD_SERVING_AS_FAIL = 'LOAD_SERVING_AS_FAIL';

//grade/class
export const LOAD_GRADE_REQUEST = 'LOAD_GRADE_REQUEST';
export const LOAD_GRADE_SUCCESS = 'LOAD_GRADE_SUCCESS';
export const LOAD_GRADE_FAIL = 'LOAD_GRADE_FAIL';

// highest educational qualifications
export const LOAD_EDUCATIONAL_QUALIFICATIONS_REQUEST =
  'LOAD_EDUCATIONAL_QUALIFICATIONS_REQUEST';
export const LOAD_EDUCATIONAL_QUALIFICATIONS_SUCCESS =
  'LOAD_EDUCATIONAL_QUALIFICATIONS_SUCCESS';
export const LOAD_EDUCATIONAL_QUALIFICATIONS_FAIL =
  'LOAD_EDUCATIONAL_QUALIFICATIONS_FAIL';

//professional qualifications

export const LOAD_PROFESSIONAL_QUALIFICATIONS_REQUEST =
  'LOAD_PROFESSIONAL_QUALIFICATIONS_REQUEST';
export const LOAD_PROFESSIONAL_QUALIFICATIONS_SUCCESS =
  'LOAD_PROFESSIONAL_QUALIFICATIONS_SUCCESS';
export const LOAD_PROFESSIONAL_QUALIFICATIONS_FAIL =
  'LOAD_PROFESSIONAL_QUALIFICATIONS_FAIL';

//classification
export const LOAD_CLASSIFICATION_REQUEST = 'LOAD_CLASSIFICATION_REQUEST';
export const LOAD_CLASSIFICATION_SUCCESS = 'LOAD_CLASSIFICATION_SUCCESS';
export const LOAD_CLASSIFICATION_FAIL = 'LOAD_CLASSIFICATION_FAIL';

//language medium
export const LOAD_LANGUAGE_REQUEST = 'LOAD_LANGUAGE_REQUEST';
export const LOAD_LANGUAGE_SUCCESS = 'LOAD_LANGUAGE_SUCCESS';
export const LOAD_LANGUAGE_FAIL = 'LOAD_LANGUAGE_FAIL';

// work involved
export const LOAD_WORKINVOLVED_REQUEST = 'LOAD_WORKINVOLVED_REQUEST';
export const LOAD_WORKINVOLVED_SUCCESS = 'LOAD_WORKINVOLVED_SUCCESS';
export const LOAD_WORKINVOLVED_FAIL = 'LOAD_WORKINVOLVED_FAIL';

// work involved
export const LOAD_CATEGORIES_REQUEST = 'LOAD_CATEGORIES_REQUEST';
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAIL = 'LOAD_CATEGORIES_FAIL';

export const LOAD_ARRIVAL_DISPATCH_REQUEST = 'LOAD_ARRIVAL_DISPATCH_REQUEST';
export const LOAD_ARRIVAL_DISPATCH_SUCCESS = 'LOAD_ARRIVAL_DISPATCH_SUCCESS';
export const LOAD_ARRIVAL_DISPATCH_FAIL = 'LOAD_ARRIVAL_DISPATCH_FAIL';

export const LOAD_WORKING_TIME_REQUEST = 'LOAD_WORKING_TIME_REQUEST';
export const LOAD_WORKING_TIME_SUCCESS = 'LOAD_WORKING_TIME_SUCCESS';
export const LOAD_WORKING_TIME_FAIL = 'LOAD_WORKING_TIME_FAIL';

export const LOAD_STAFF_WORKING_TIME_REQUEST =
  'LOAD_STAFF_WORKING_TIME_REQUEST';
export const LOAD_STAFF_WORKING_TIME_SUCCESS =
  'LOAD_STAFF_WORKING_TIME_SUCCESS';
export const LOAD_STAFF_WORKING_TIME_FAIL = 'LOAD_STAFF_WORKING_TIME_FAIL';

export const LOAD_STAFF_ARRIVAL_DISPATCH_REQUEST =
  'LOAD_STAFF_ARRIVAL_DISPATCH_REQUEST';
export const LOAD_STAFF_ARRIVAL_DISPATCH_SUCCESS =
  'LOAD_STAFF_ARRIVAL_DISPATCH_SUCCESS';
export const LOAD_STAFF_ARRIVAL_DISPATCH_FAIL =
  'LOAD_STAFF_ARRIVAL_DISPATCH_FAIL';

export const LOAD_WORKING_TIMES_REQUEST = 'LOAD_WORKING_TIMES_REQUEST';
export const LOAD_WORKING_TIMES_SUCCESS = 'LOAD_WORKING_TIMES_SUCCESS';
export const LOAD_WORKING_TIMES_FAIL = 'LOAD_WORKING_TIMES_FAIL';

export const LOAD_ARRIVAL_DEPARTURES_REQUEST =
  'LOAD_ARRIVAL_DEPARTURES_REQUEST';
export const LOAD_ARRIVAL_DEPARTURES_SUCCESS =
  'LOAD_ARRIVAL_DEPARTURES_SUCCESS';
export const LOAD_ARRIVAL_DEPARTURES_FAIL = 'LOAD_ARRIVAL_DEPARTURES_FAIL';
