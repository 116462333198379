import * as types from 'store/user/types';

const initialState = {
  loading: false,
  users: [],
  user:{},
  paginationOption:{},
  errors: {},
};

const userReducer = (state = initialState, action) => {
   switch (action.type) {
     case types.LOAD_USERS_REQUEST:
       return {
         ...state,
         loading: true,
       };

     case types.LOAD_USERS_SUCCESS:
       return {
         ...state,
         loading: false,
         users: action.payload.results,
         paginationOption: {
           totalItems: action.payload?.totalItems,
           page: action.payload?.page,
           pageSize: action.payload?.pageSize,
           numberOfPages: action.payload?.numberOfPages,
           displayStart: action.payload?.displayStart,
           displayEnd: action.payload?.displayEnd,
           displayCount: action.payload?.displayCount,
         },
       };

     case types.LOAD_USERS_FAIL:
       return {
         ...state,
         loading: false,
         errors: action.payload,
       };

     case types.LOAD_USER_REQUEST:
       return {
         ...state,
         loading: true,
       };

     case types.LOAD_USER_SUCCESS:
       return {
         ...state,
         loading: false,
         user: action.payload,
       };

     case types.LOAD_USER_FAIL:
       return {
         ...state,
         loading: false,
         errors: action.payload,
       };

     default:
       return state;
   }
};

export default userReducer;
