import React, { useMemo } from 'react';
import { Calendar } from 'primereact/calendar';

function DatePicker({
  name,
  values,
  errors,
  touches,
  onChange,
  placeholder,
  label,
  disabled,
  minDate,
  maxDate,
  className,
  secondsSelect,
}) {
  const isInvalid = useMemo(() => {
    return touches[name] && errors[name];
  }, [touches, errors, name]);

  return (
    <div>
      <label
        htmlFor={label}
        className='block text-[11px] mb-2 font-bold text-sky-900'
      >
        {label}
      </label>
      <Calendar
        value={values[name]}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        placeholder={placeholder}
        dateFormat='HH:mm:ss'
        id={name}
        inputId={name}
        className={className ? className : 'h-9 w-full'}
        timeOnly
        showSeconds={secondsSelect}
      />

      {isInvalid ? (
        <p className='text-red-500 font-[600] text-[10px] mt-1'>
          {errors[name]}
        </p>
      ) : null}
    </div>
  );
}
export default DatePicker;
