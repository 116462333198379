import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pushDocuments } from 'store/state/actions';

function FileUpload({ name }) {
  const dispatch = useDispatch();
  const upload = useRef(null);

  const [data, setData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(new FormData());

  const handleUploadFile = (event) => {
    const filesArray = Array.from(event.target.files);

    setData([...data, ...filesArray]);

    Promise.all(
      filesArray.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });
      })
    ).then((base64Array) => {
      const newSelectedFiles = filesArray.map((file, index) => {
        return {
          file: file,
          base64: base64Array[index],
        };
      });

      for (let i = 0; i < newSelectedFiles.length; i++) {
        const { file } = newSelectedFiles[i];

        selectedFiles.append('doc', file, file.name);
      }

      setSelectedFiles(selectedFiles);
    });
  };

  useEffect(() => {
    dispatch(pushDocuments(selectedFiles));
  }, [selectedFiles]);

  //
  const handleRemoveFile = (filename) => {
    setSelectedFiles((prevSelectedFiles) => {
      const newSelectedFiles = new FormData();

      for (let [key, value] of prevSelectedFiles.entries()) {
        if (value.name !== filename) {
          newSelectedFiles.append(key, value);
        }
      }

      return newSelectedFiles;
    });

    setData((prevData) => prevData.filter((file) => file.name !== filename));
  };

  return (
    <div className='flex items-center justify-center'>
      <div className='mx-auto w-full bg-white'>
        <div className='py-6 px-9'>
          <div className='mb-4'>
            <label className='mb-5 block text-md font-semibold text-sky-900'>
              Upload File
            </label>

            <div className='mb-8'>
              <input
                type='file'
                name='file'
                id='file'
                className='sr-only'
                multiple
                onChange={handleUploadFile}
              />
              <label
                htmlFor='file'
                className='relative flex items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-6 text-center'
              >
                <div>
                  <span className='mb-2 block text-sm font-display font-semibold text-[#07074D]'>
                    Select Files
                  </span>
                  <span className='mb-2 block text-xs font-medium text-[#6B7280]'>
                    To
                  </span>
                  <span
                    className='inline-flex rounded border bg-gray-100 cursor-pointer border-[#e0e0e0] py-2 px-7 text-xs font-display text-[#07074D]'
                    onClick={() => upload?.current?.click()}
                  >
                    Upload
                  </span>
                </div>
              </label>
            </div>
            {data?.map((item, key) => {
              return (
                <div className='rounded-md bg-[#F5F7FB] py-4 px-8 mt-2'>
                  <div className='flex items-center justify-between'>
                    <span className='truncate pr-3 text-xs font-medium text-[#07074D]'>
                      {item?.name}
                    </span>
                    <button
                      className='text-[#07074D]'
                      onClick={() => handleRemoveFile(item?.name)}
                      type='button'
                    >
                      <svg
                        width='10'
                        height='10'
                        viewBox='0 0 10 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z'
                          fill='currentColor'
                        />
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z'
                          fill='currentColor'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
