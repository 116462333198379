import axios from 'config/axios';
import moment from 'moment';
import * as types from 'store/leave/types';

export const loadLeaves = (page, pageSize, filters) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_LEAVES_REQUEST });

    const { data } = await axios.get(
      `staffs-leave?startDate=${
        filters?.startDate
          ? filters?.startDate
          : moment(new Date()).format('YYYY-MM-DD')
      }&endDate=${
        filters?.endDate
          ? filters?.endDate
          : moment(new Date()).format('YYYY-MM-DD')
      }${filters.leaveType ? '&LeaveTypeId=' + filters.leaveType?.id : ''}${
        filters.query ? '&searchString=' + filters.query : ''
      }&page=${page}&pageSize=${pageSize}`
    );

    dispatch({
      type: types.LOAD_LEAVES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_LEAVES_FAIL,
      payload: error,
    });
  }
};

export const loadStaffLeave = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_STAFF_LEAVE_REQUEST });

    const { data } = await axios.get(`get-staff-leave-by-id?id=${id}`);

    dispatch({
      type: types.LOAD_STAFF_LEAVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_STAFF_LEAVE_FAIL,
      payload: error,
    });
  }
};

export const loadLeave = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_LEAVE_REQUEST });

    const { data } = await axios.get(`get-leave-by-id?id=${id}`);

    dispatch({
      type: types.LOAD_LEAVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_LEAVE_FAIL,
      payload: error,
    });
  }
};
export const loadLeaveStatus = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_LEAVE_STATUS_REQUEST });
    const { data } = await axios.get('leave-status');
    dispatch({
      type: types.LOAD_LEAVE_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_LEAVE_STATUS_FAIL,
      payload: error,
    });
  }
};

export const loadTypes = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_LEAVE_TYPE_REQUEST });
    const { data } = await axios.get('leave-type');
    dispatch({
      type: types.LOAD_LEAVE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_LEAVE_TYPE_FAIL,
      payload: error,
    });
  }
};

export const storeItem = (values) => async (dispatch, getState) => {
  return await axios.post('/create-leave', values);
};
export const updateItem = (values) => async (dispatch, getState) => {
  return await axios.post('/update-leave-by-id', values);
};

export const allocateItem = (values) => async (dispatch, getState) => {
  return await axios.post('/allocate-leave', values);
};

export const archiveItem = (id) => async (dispatch, getState) => {
  return await axios.delete(`soft-delete-by-id?id=${id}`);
};
