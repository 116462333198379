import * as types from 'store/leave/types';

const initialState = {
  loading: false,
  leaves: [],
  staffLeave: [],
  leave: {},
  paginationOption: {},
  leaveStatus: {},
  leaveTypes: [],
  leaveLoading: false,
};

const leaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_LEAVES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_LEAVES_SUCCESS:
      return {
        ...state,
        loading: false,
        leaves: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_LEAVES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_STAFF_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_STAFF_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        staffLeave: action.payload.result,
      };

    case types.LOAD_STAFF_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.LOAD_LEAVE_REQUEST:
      return {
        ...state,
        leaveLoading: true,
      };

    case types.LOAD_LEAVE_SUCCESS:
      return {
        ...state,
        leaveLoading: false,
        leave: action.payload.result,
      };

    case types.LOAD_LEAVE_FAIL:
      return {
        ...state,
        leaveLoading: false,
        errors: action.payload,
      };

    case types.LOAD_LEAVE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_LEAVE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveStatus: action.payload.result,
      };

    case types.LOAD_LEAVE_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.LOAD_LEAVE_TYPE_REQUEST:
      return {
        ...state,
      };

    case types.LOAD_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        leaveTypes: action.payload.result,
      };

    case types.LOAD_LEAVE_TYPE_FAIL:
      return {
        ...state,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default leaveReducer;
