import React from 'react';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Button } from '../Button';

function Confirmation({ visible, setVisible, accept, message }) {
  const modalFooter = (
    <div className='flex justify-between px-4'>
      <Button
        name='X No'
        onClick={() => setVisible(false)}
        className=' bg-gray-50 text-gray-900 w-28 font-bold text-[12px] h-9 px-4 rounded focus:outline-none focus:shadow-outline'
      />

      <Button
        name='✓ Yes'
        onClick={accept}
        className='bg-gray-900 text-white w-28 font-bold text-[12px] h-9 px-4 rounded focus:outline-none focus:shadow-outline'
      />
    </div>
  );

  return (
    <div>
      <ConfirmPopup
        visible={visible}
        onHide={() => setVisible(false)}
        message={message}
        className='text-sm py-6 mb-6 px-4'
        icon='pi pi-exclamation-triangle'
        reject={() => setVisible(false)}
        footer={modalFooter}
      />
    </div>
  );
}

export default Confirmation;
