import * as types from 'store/audit-logs/types';

const initialState = {
  loading: false,
  events: [],
  paginationOption: {},
};

const auditReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_AUDITS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_AUDITS_SUCCESS:
      return {
        ...state,
        loading: false,
        auditLogs: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_AUDITS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default auditReducer;
