import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import toast from 'lib/toast';
import NoData from '../ResourceTable/NoData';
import useExpandTableHooks from './hooks';
import { pushPage, pushPageFirst, pushPageSize } from 'store/state/actions';
import ActionMenu from './ActionMenu';
import DeleteModal from './DeleteModal';
import ArchiveModal from './ArchiveModal';

function ExpandResourceTable({
  columns,
  data,
  loadData,
  pagination,
  pageSize,
  allowExpansion,
  selectedRows,
  setSelectedRows,
  rowExpansionTemplate,
  dataKey,
  selection,
  header,
  loading,
  createRoute,
  viewRoute,
  editRoute,
  archiveMenu,
  deleteMenu,
  deleteAction,
  archiveAction,
  viewModal,
  editModal,
  modalData,
  actionMenu,
}) {
  const dispatch = useDispatch();

  //use state hooks
  const [archiveVisible, setArchiveVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const {
    CellClass,
    filters,
    first,
    handlePage,
    item,
    page,
    perPage,
    setFirst,
    setItem,
    setPage,
    setPerPage,
    statePage,
    expandedRows,
    setExpandedRows,
    rowClick,
  } = useExpandTableHooks(pageSize);

  //use effect for dispatch actions
  useEffect(() => {
    if (loadData) {
      dispatch(loadData(page, perPage, filters));
      dispatch(pushPage(page));
      dispatch(pushPageSize(perPage));
      dispatch(pushPageFirst(first));
    }
  }, [
    dispatch,
    perPage,
    page,
    first,
    filters?.startDate,
    filters?.query,
    filters?.category?.id,
    filters?.section?.id,
    filters?.endDate,
    filters?.leaveType?.id,
    filters?.staff?.id,
  ]);

  const actionTemplate = (row) => {
    return (
      <React.Fragment>
        <ActionMenu
          row={row}
          setArchiveVisible={setArchiveVisible}
          setDeleteVisible={setDeleteVisible}
          setItem={setItem}
          viewRoute={viewRoute}
          editRoute={editRoute}
          archiveMenu={archiveMenu}
          deleteMenu={deleteMenu}
          viewModal={viewModal}
          editModal={editModal}
          modalData={modalData}
        />
      </React.Fragment>
    );
  };

  const tableColumns = columns?.map((col, i) => {
    return (
      <Column
        key={i}
        field={col.field}
        header={col.header}
        body={col.body}
        style={col.style}
        align={col.align ? col.align : 'left'}
        alignHeader={col.headerAlign ? col.headerAlign : 'left'}
      />
    );
  });

  //with action menu
  const modifiedColumns = useMemo(() => {
    return [
      selection && (
        <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
      ),

      <Column expander={allowExpansion} style={{ width: '5rem' }} />,
      ...tableColumns,
    ];
  }, []);

  //with action menu
  const actionColumns = useMemo(() => {
    return [
      ...modifiedColumns,
      <Column header='Actions' body={actionTemplate} />,
    ];
  }, []);

  const resourceColumns = actionMenu ? actionColumns : modifiedColumns;

  const onDeleteItem = React.useCallback(async (item) => {
    await dispatch(deleteAction(item?.id));
    await dispatch(loadData(page, perPage, filters));
    toast.success('Item Deleted Successfully');
  }, []);

  const onArchiveItem = React.useCallback(async (item) => {
    await dispatch(archiveAction(item?.id));
    toast.success('Item Archived Successfully');
    await dispatch(loadData(page, perPage, filters));
  }, []);

  return (
    <React.Fragment>
      <div className='overflow-hidden shadow-sm'>
        <DataTable
          value={data}
          header={header}
          dataKey={dataKey}
          responsiveLayout='stack'
          breakpoint='960px'
          emptyMessage={NoData}
          className='text-[12px] tracking-widest font-medium'
          cellClassName={CellClass}
          rowHover
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows}
          selectionMode={rowClick ? null : 'checkbox'}
          loading={loading}
        >
          {resourceColumns}
        </DataTable>

        {pagination?.totalItems ? (
          <div className='flex md:justify-end justify-center'>
            <Paginator
              rows={perPage}
              totalRecords={pagination?.totalItems}
              rowsPerPageOptions={[10, 20, 50, 100, 200]}
              onPageChange={handlePage}
              first={first}
              className='w-full justify-end font-display'
            />
          </div>
        ) : null}
      </div>
      <DeleteModal
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        item={item}
        onDeleteItem={onDeleteItem}
      />
      <ArchiveModal
        visible={archiveVisible}
        setVisible={setArchiveVisible}
        item={item}
        onArchiveItem={onArchiveItem}
      />
    </React.Fragment>
  );
}

export default ExpandResourceTable;
