import * as types from 'store/sms/types';

const initialState = {
  loading: false,
  paginationOption: {},
  sms: [],
  templates: [],
};

const smsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_SMS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        sms: action.payload.results,
        paginationOption: {
          totalItems: action.payload?.totalItems,
          page: action.payload?.page,
          pageSize: action.payload?.pageSize,
          numberOfPages: action.payload?.numberOfPages,
          displayStart: action.payload?.displayStart,
          displayEnd: action.payload?.displayEnd,
          displayCount: action.payload?.displayCount,
        },
      };

    case types.LOAD_SMS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload.result,
      };

    case types.LOAD_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default smsReducer;
