import React, { useMemo } from 'react';
import { Calendar } from 'primereact/calendar';

function DatePicker({
  name,
  values,
  errors,
  touches,
  onChange,
  placeholder,
  label,
  disabled,
  minDate,
  maxDate,
}) {
  const isInvalid = useMemo(() => {
    return touches[name] && !values[name] ? true : null;
  }, [touches, errors, name]);
  return (
    <div>
      <label
        htmlFor={label}
        className='block text-[11px] mb-2 font-bold text-sky-900'
      >
        {label}
      </label>
      <Calendar
        value={values[name] ? new Date(values[name]) : null}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        placeholder={placeholder}
        dateFormat='dd-mm-yy'
        id={name}
        inputId={name}
        className={`h-9 w-full ${isInvalid ? 'border-red-500' : null}`}
      />

      {isInvalid ? (
        <p className='text-red-500 font-[600] text-[10px] mt-1'>
         {errors[name]}
        </p>
      ) : null}
    </div>
  );
}
export default DatePicker;
