import React, { useState } from 'react';
import { MultiSelect as Component } from 'primereact/multiselect';

function MultiSelect({
  selectedItem,
  onChange,
  options,
  placeholder,
  optionValue,
  optionLabel,
  label,
  name,
  disabled,
}) {
  return (
    <div>
      <label
        htmlFor={name}
        className='block text-[11px] mb-2 font-bold text-sky-900'
      >
        {label}
      </label>

      <Component
        value={selectedItem}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        optionValue={optionValue}
        placeholder={placeholder}
        disabled={disabled}
        className='w-full text-xs border-none bg-gray-100 h-9'
        display='chip'
      />
    </div>
  );
}

export default MultiSelect;
