import React, { useMemo } from 'react';

function Input({
  name,
  label,
  type,
  onChange,
  className,
  placeholder,
  values,
  errors,
  touches,
  onBlur,
  onFocus,
  disabled,
}) {
  const isInvalid = useMemo(() => {
    return touches[name] && errors[name];
  }, [touches, errors, name]);
  return (
    <>
      <label
        htmlFor={name}
        className='block text-[11px] mb-2 font-bold text-sky-900'
      >
        {label}
      </label>

      <input
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        defaultValue={values[name]}
        disabled={disabled}
        className={
          className
            ? className
            : 'flex items-center w-full px-2 h-9 py-4 border text-xs border-[#f9f8fc] rounded-md'
        }
      />

      {isInvalid ? (
        <p className='text-red-500 font-[600] text-[10px] mt-1'>
          {errors[name]}
        </p>
      ) : null}
    </>
  );
}

export default Input;
