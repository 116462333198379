import React, { useMemo } from 'react';

function Textarea({
  name,
  label,
  rows,
  values,
  errors,
  touches,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  disabled,
}) {
  const isInvalid = useMemo(() => {
    return touches[name] && errors[name];
  }, [touches, errors, name]);

  return (
    <>
      <label
        htmlFor={name}
        className='block text-[11px] mb-2 font-bold text-sky-900'
      >
        {label}
      </label>

      <textarea
        id={name}
        name={name}
        rows={rows}
        className='mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm border text-xs border-gray-300 rounded-md p-2 resize-none'
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={values[name]}
        disabled={disabled}
      />
      {isInvalid && (
        <p className='text-red-500 font-[600] text-[10px] mt-1'>
          {errors[name]}
        </p>
      )}
    </>
  );
}

export default Textarea;
