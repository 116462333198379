import { combineReducers } from 'redux';
import { persistReducer, PURGE } from 'redux-persist';
import persistConfig from 'config/persist';

import authReducer from 'store/auth/reducers';
import stateReducer from './state/reducers';
import userReducer from './user/reducers';
import staffReducer from './staff/reducers';
import addonReducer from './addon/reducers';
import attendanceReducer from './attendance/reducers';
import roleReducer from './role/reducers';
import reportReducer from './report/reducers';
import leaveReducer from './leave/reducers';
import eventReducer from './event/reducers';
import dashboardReducer from './dashboard/reducers';
import organizationReducer from './organization/reducers';
import smsReducer from './sms/reducers';
import settingsReducer from './settings/reducers';
import auditReducer from './audit-logs/reducers';
//

const appReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  state: stateReducer,
  users: userReducer,
  staff: staffReducer,
  addon: addonReducer,
  attendance: attendanceReducer,
  roles: roleReducer,
  reports: reportReducer,
  leave: leaveReducer,
  event: eventReducer,
  dashboard: dashboardReducer,
  organization: organizationReducer,
  sms: smsReducer,
  settings: settingsReducer,
  audits: auditReducer,
});

const rootReducer = (state, action) => {
  if (action.type === PURGE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
