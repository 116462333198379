import * as types from 'store/role/types';

const initialState = {
  loading: false,
  rolesPermission: [],
  roles: [],
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };

    case types.LOAD_ROLES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.LOAD_ALL_ROLES_WITH_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_ALL_ROLES_WITH_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        rolesPermission: action.payload,
      };

    case types.LOAD_ALL_ROLES_WITH_PERMISSION_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default roleReducer;
