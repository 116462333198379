import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link } from 'react-router-dom';
import * as icons from 'assets/icons';

function Breadcrumb({ routes }) {
  const breadcrumbs = useBreadcrumbs(routes);
  return (
    <div className='w-full h-10 py-6  px-6 inline-flex items-center flex-row'>
      {breadcrumbs.map(({ breadcrumb, key }) => {
        if (breadcrumb.key === '/reports') {
          return null;
        }

        return (
          <ol
            className='inline-flex items-center space-x-1 md:space-x-3'
            key={key}
          >
            <li className='inline-flex items-center'>
              <Link
                to={breadcrumb.key}
                className='inline-flex items-center text-xs font-bold text-gray-700  dark:text-gray-400 dark:hover:text-white hover:text-orange-500'
              >
                {breadcrumb}
              </Link>
              {breadcrumbs[breadcrumbs?.length - 1].key !== breadcrumb.key && (
                <div className='flex items-center px-1 w-6 h-6'>
                  {icons.ARROW_FORWARD_ICON}
                </div>
              )}
            </li>
          </ol>
        );
      })}
    </div>
  );
}

export default Breadcrumb;
