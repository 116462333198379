import * as types from 'store/addon/types';

const initialState = {
  loading: false,
  titles: [],
  errors: [],
  ethnicity: [],
  religion: [],
  subject: [],
  maritalStatus: [],
  position: [],
  salaryMethod: [],
  grade: [],
  educational: [],
  professional: [],
  classification: [],
  language: [],
  categories: [],
  arrival: [],
  workingTime: [],
  staffWorkingTime: [],
  staffArrivalDispatch: [],
  workingTimes: [],
  arrivalDepartures: [],
};

const addonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_TITLE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        titles: action.payload.result,
      };

    case types.LOAD_TITLE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_ETHNICITY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_ETHNICITY_SUCCESS:
      return {
        ...state,
        loading: false,
        ethnicity: action.payload.result,
      };

    case types.LOAD_ETHNICITY_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_RELIGION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_RELIGION_SUCCESS:
      return {
        ...state,
        loading: false,
        religion: action.payload.result,
      };

    case types.LOAD_RELIGION_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //subject
    case types.LOAD_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        subject: action.payload.result,
      };

    case types.LOAD_SUBJECT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //marital status
    case types.LOAD_MARITAL_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_MARITAL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        maritalStatus: action.payload.result,
      };

    case types.LOAD_MARITAL_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //position
    case types.LOAD_POSITION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        position: action.payload.result,
      };

    case types.LOAD_POSITION_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //salary method

    case types.LOAD_SALARY_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_SALARY_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        salaryMethod: action.payload.result,
      };

    case types.LOAD_SALARY_METHOD_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //grade

    case types.LOAD_GRADE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_GRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        grade: action.payload.result,
      };

    case types.LOAD_GRADE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //educational
    case types.LOAD_EDUCATIONAL_QUALIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_EDUCATIONAL_QUALIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        educational: action.payload.result,
      };

    case types.LOAD_EDUCATIONAL_QUALIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //professional
    case types.LOAD_PROFESSIONAL_QUALIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_PROFESSIONAL_QUALIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        professional: action.payload.result,
      };

    case types.LOAD_PROFESSIONAL_QUALIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //classifications
    case types.LOAD_CLASSIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        classification: action.payload.result,
      };

    case types.LOAD_CLASSIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    //language medium

    case types.LOAD_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        language: action.payload.result,
      };

    case types.LOAD_LANGUAGE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    //work involved

    case types.LOAD_WORKINVOLVED_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_WORKINVOLVED_SUCCESS:
      return {
        ...state,
        loading: false,
        workInvolved: action.payload.result,
      };

    case types.LOAD_WORKINVOLVED_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.result,
      };

    case types.LOAD_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.LOAD_ARRIVAL_DISPATCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_ARRIVAL_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        arrival: action.payload.result,
      };

    case types.LOAD_ARRIVAL_DISPATCH_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.LOAD_WORKING_TIME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_WORKING_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        workingTime: action.payload.result,
      };

    case types.LOAD_WORKING_TIME_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_STAFF_WORKING_TIME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_STAFF_WORKING_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        staffWorkingTime: action.payload.result,
      };

    case types.LOAD_STAFF_WORKING_TIME_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_STAFF_ARRIVAL_DISPATCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_STAFF_ARRIVAL_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        staffArrivalDispatch: action.payload.result,
      };

    case types.LOAD_STAFF_ARRIVAL_DISPATCH_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_WORKING_TIMES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_WORKING_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        workingTimes: action.payload.result,
      };

    case types.LOAD_WORKING_TIMES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.LOAD_ARRIVAL_DEPARTURES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_ARRIVAL_DEPARTURES_SUCCESS:
      return {
        ...state,
        loading: false,
        arrivalDepartures: action.payload.result,
      };

    case types.LOAD_ARRIVAL_DEPARTURES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default addonReducer;
