import React, { useMemo } from 'react';
import SelectComponent from 'react-select';

function Select({
  name,
  label,
  values,
  errors,
  touches,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  loading,
  options,
  getOptionValue,
  getOptionLabel,
  disabled,
}) {
  const isInvalid = useMemo(() => {
    return touches[name] && !values[name] && errors[name] ? true : null;
  }, [touches, errors, name]);
  return (
    <>
      <label
        htmlFor={name}
        className='block text-[11px] mb-2 font-bold text-sky-900'
      >
        {label}
      </label>
      <SelectComponent
        isClearable
        isSearchable
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isLoading={loading}
        isDisabled={disabled}
        name={name}
        getOptionLabel={(option) => option[getOptionLabel]}
        getOptionValue={(option) => option[getOptionValue]}
        defaultValue={values[name]}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,

          colors: {
            ...theme.colors,

            text: '#0F1A2D',
            primary25: '#E5E7EB',
            primary50: '#E5E7EB',

            primary: '#111B30',
          },
        })}
        className={`mt-1  ${isInvalid ? 'border-red-500' : null} text-xs `}
        styles={{
          control: () => ({
            display: 'flex',
            backgroundColor: '#F3F3F3',
            borderRadius: '4px',
          }),
        }}
      />
      {isInvalid ? (
        <p className='text-red-500 font-[600] text-[10px] mt-1'>
          {label ? label : name} is required
        </p>
      ) : null}
    </>
  );
}

export default Select;
