//title
export const LOAD_LEAVE_REPORTS_REQUEST = 'LOAD_LEAVE_REPORTS_REQUEST';
export const LOAD_LEAVE_REPORTS_SUCCESS = 'LOAD_LEAVE_REPORTS_SUCCESS';
export const LOAD_LEAVE_REPORTS_FAIL = 'LOAD_LEAVE_REPORTS_FAIL';

export const LOAD_LEAVE_REPORT_REQUEST = 'LOAD_LEAVE_REPORT_REQUEST';
export const LOAD_LEAVE_REPORT_SUCCESS = 'LOAD_LEAVE_REPORT_SUCCESS';
export const LOAD_LEAVE_REPORT_FAIL = 'LOAD_LEAVE_REPORT_FAIL';

export const LOAD_LEAVE_DOWNLOAD_REPORT_REQUEST =
  'LOAD_LEAVE_DOWNLOAD_REPORT_REQUEST';
export const LOAD_LEAVE_DOWNLOAD_REPORT_SUCCESS =
  'LOAD_LEAVE_DOWNLOAD_REPORT_SUCCESS';
export const LOAD_LEAVE_DOWNLOAD_REPORT_FAIL =
  'LOAD_LEAVE_DOWNLOAD_REPORT_FAIL';

export const LOAD_ATTENDANCE_REPORTS_REQUEST =
  'LOAD_ATTENDANCE_REPORTS_REQUEST';
export const LOAD_ATTENDANCE_REPORTS_SUCCESS =
  'LOAD_ATTENDANCE_REPORTS_SUCCESS';
export const LOAD_ATTENDANCE_REPORTS_FAIL = 'LOAD_ATTENDANCE_REPORTS_FAIL';

//working time
export const LOAD_WORKING_TIME_REPORT_REQUEST =
  'LOAD_WORKING_TIME_REPORT_REQUEST';
export const LOAD_WORKING_TIME_REPORT_SUCCESS =
  'LOAD_WORKING_TIME_REPORT_SUCCESS';
export const LOAD_WORKING_TIME_REPORT_FAIL = 'LOAD_WORKING_TIME_REPORT_FAIL';

export const LOAD_LEAVE_SUMMARY_REPORT_REQUEST =
  'LOAD_LEAVE_SUMMARY_REPORT_REQUEST';
export const LOAD_LEAVE_SUMMARY_REPORT_SUCCESS =
  'LOAD_LEAVE_SUMMARY_REPORT_SUCCESS';
export const LOAD_LEAVE_SUMMARY_REPORT_FAIL = 'LOAD_LEAVE_SUMMARY_REPORT_FAIL';

export const LOAD_STAFF_REPORT_REQUEST = 'LOAD_STAFF_REPORT_REQUEST';
export const LOAD_STAFF_REPORT_SUCCESS = 'LOAD_LOAD_STAFF_REPORT_SUCCESS';
export const LOAD_STAFF_REPORT_FAIL = 'LOAD_STAFF_REPORT_FAIL';

//load carryforward monthly leave summary
export const LOAD_STAFF_MONTHLY_LEAVE_REPORT_REQUEST =
  'LOAD_STAFF_MONTHLY_LEAVE_REPORT_REQUEST';
export const LOAD_STAFF_MONTHLY_LEAVE_REPORT_SUCCESS =
  'LOAD_STAFF_MONTHLY_LEAVE_REPORT_SUCCESS';
export const LOAD_STAFF_MONTHLY_LEAVE_REPORT_FAIL =
  'LOAD_STAFF_MONTHLY_LEAVE_REPORT_FAIL';
