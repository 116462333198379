import React from 'react';

function Footer() {
  return (
    <footer>
      <div className='w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-end'>
        <ul className='flex flex-wrap items-center tracking-wider mt-3 text-sm font-medium text-gray-500'>
          <span className='text-xs text-gray-500 sm:text-center dark:text-gray-400'>
            © {new Date().getFullYear()} &nbsp;
            <a href='https://speedcodelabs.com/' className='hover:underline'>
              SpeedCodeLabs™
            </a>
            . All Rights Reserved.
          </span>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
