import camelcaseKeys from 'camelcase-keys';
import * as types from 'store/auth/types';

const initialState = {
  loading: false,
  tokenType: 'Bearer',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        ...camelcaseKeys(action.payload),
      };

    default:
      return state;
  }
};

export default authReducer;
