import React, { useMemo } from 'react';

function Checkbox({
  name,
  label,
  description,
  values,
  errors,
  touches,
  onChange,
  onBlur,
  onFocus,
}) {
  const isInvalid = useMemo(() => {
    return touches[name] && errors[name];
  }, [touches, errors, name]);

  return (
    <div className='flex items-start'>
      <div className='flex items-center h-5'>
        <input
          id={name}
          name={name}
          type='checkbox'
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          checked={values[name]}
          className={`focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded   ${
            isInvalid ? 'border-red-500' : null
          }`}
        />
      </div>

      <div className='ml-3 text-sm'>
        <label
          htmlFor={name}
          className='block text-[11px] font-bold text-sky-900'
        >
          {label}
        </label>
        {description != null && <p className='text-gray-700'>{description}</p>}
      </div>
      {isInvalid && (
        <p className='text-red-500 font-[600] text-[10px] mt-1'>
          {errors[name]}
        </p>
      )}
    </div>
  );
}

export default Checkbox;
