import { Footer, Header, SideBar } from 'components/base';
import { Outlet } from 'react-router-dom';

function Index() {
  return (
    <div className='min-h-full flex flex-col'>
      <Header />
      <main className='flex  flex-row'>
        <div className='h-full md:block hidden'>
          <SideBar />
        </div>
        <div className='w-full bg-white'>
          <Outlet />
          <Footer />
        </div>
      </main>
    </div>
  );
}

export default Index;
