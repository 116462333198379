import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { IoLogOut } from 'react-icons/io5';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { logout } from 'store/auth/actions';
import User from 'assets/images/user2.png';
import Logo from 'assets/images/logo.png';
import Columns from '../SideBar/Menu';
import { imageUrl } from 'config/env';
import moment from 'moment';
import Clock from 'react-live-clock';

function Header() {
  const menu = useRef();
  const dispatch = useDispatch();

  const [isSideMenuOpen, setisSideMenuOpen] = useState(false);

  const showSideMenu = () => {
    isSideMenuOpen ? setisSideMenuOpen(false) : setisSideMenuOpen(true);
  };

  const getGreetingMessage = () => {
    const hour = new Date().getHours();

    if (hour >= 12) {
      if (hour >= 16) {
        return 'Good Evening !';
      } else {
        return 'Good Afternoon !';
      }
    } else {
      return 'Good Morning !';
    }
  };

  let items = [
    {
      icon: <IoLogOut className='mr-2 w-5 h-5' />,
      label: 'Sign Out',
      className: 'text-sm',
      command: () => onLogout(),
    },
  ];

  const onLogout = async () => {
    await dispatch(logout());
    window.location.reload(true);
  };

  const { user } = useSelector((state) => state.auth);

  return (
    <header className='flex px-3 h-14 items-center justify-between bg-gray-50'>
      <div className='flex items-center'>
        <img src={Logo} alt='logo' className='w-10 h-10 mr-4' />
        <h1 className='text-[#ad0b21] text-sm font-bold'>St.John's College</h1>
      </div>

      <div className='mr-5 flex items-center'>
        <div className='w-36 h-11 text-red-600 tracking-widest text-sm font-bold rounded-sm mr-6 flex flex-col justify-center items-center'>
          <span className='text-[11px]'>
            {moment(new Date()).format('DD-MM-YYYY')}
          </span>{' '}
          <Clock format={'HH:mm:ss'} ticking={true} />
        </div>

        <div className='font-display font-bold text-xs flex-col text-gray-900 tracking-widest mr-4 md:block hidden'>
          <span className='text-[12px]'>{getGreetingMessage()}</span>
          <br />
          {user?.lastName}
        </div>

        <img
          className='h-11 w-12 rounded-full hover:border-[2px] hover:border-red-400'
          src={user?.avatar ? imageUrl + user?.avatar : User}
          alt='user'
          onClick={(e) => menu.current.toggle(e)}
        />

        <Menu model={items} popup ref={menu} />
      </div>

      <div className='mr-1 flex md:hidden'>
        <div
          className='bg-gray-100 inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-400 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
          onClick={() => showSideMenu()}
        >
          {isSideMenuOpen ? (
            <XIcon className='block h-8 w-8' aria-hidden='true' />
          ) : (
            <MenuIcon className='block h-8 w-8' aria-hidden='true' />
          )}
        </div>
        {isSideMenuOpen ? SideMenu() : ''}
      </div>
    </header>
  );
}

function SideMenu() {
  return (
    <div className='fixed bg-white top-16 w-[100%] h-screen left-0 z-50 '>
      {Columns.map((item, key) => (
        <NavLink
          as='nav'
          key={key}
          className='flex flex-col mt-6'
          to={item?.to}
        >
          <div className='flex items-center py-3 px-3 text-[#757c9f] font-medium rounded-lg hover:text-[#4868d3] hover:bg-[#ebf0fe]'>
            {item?.icon}

            <span className='ml-3 text-sm'>{item?.name}</span>
          </div>
        </NavLink>
      ))}
    </div>
  );
}

export default Header;
