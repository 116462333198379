//io icons import
import { IoIosArrowForward } from 'react-icons/io';
//ai icons import
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineMobile,
  AiOutlineUser,
  AiOutlinePhone,
  AiOutlineContacts,
  AiOutlineCalendar,
  AiOutlineSchedule,
} from 'react-icons/ai';
import { FcLeave } from 'react-icons/fc';

import {

 
  HiDownload,
} from 'react-icons/hi';
import {FaChalkboardTeacher} from 'react-icons/fa'
import { TbReportMoney, TbFileDescription } from 'react-icons/tb';
import { GrDocumentUser, GrDocumentText, GrFormView } from 'react-icons/gr';
import { HiOutlineMailOpen, HiOutlineLocationMarker } from 'react-icons/hi';
import { GiTeacher } from 'react-icons/gi';
import {
  MdOutlineCastForEducation,
  MdOutlineTitle,
  MdOutlineSubtitles,
} from 'react-icons/md';
import { BiImageAdd, BiUpload, BiTime, BiFilter } from 'react-icons/bi';
import {FiUsers} from 'react-icons/fi'

//IO ICONS
export const ARROW_FORWARD_ICON = <IoIosArrowForward />;

export const HI_OUTLINE_MAIL_OPEN = (
  <HiOutlineMailOpen className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const FI_USERS = (
  <FiUsers className='h-[22px] w-[22px]' aria-hidden='true' />
);

export const BI_FILTER = (
  <BiFilter className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const HI_OUTLINE_TITLE = (
  <MdOutlineSubtitles className='h-5 w-5 text-red-900' aria-hidden='true' />
);


export const TB_DESCRIPTION = (
  <TbFileDescription className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const MD_TITLE = (
  <MdOutlineTitle className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const BI_TIME = <BiTime className='h-5 w-5 text-red-900' aria-hidden='true' />;

export const AI_SCHEDULE = (
  <AiOutlineSchedule className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const GR_VIEW = (
  <GrFormView className='h-7 w-7 text-red-900' aria-hidden='true' />
);

export const HI_DOWNLOAD = (
  <HiDownload className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const GR_DOCUMENT = (
  <GrDocumentText className='h-5 w-5 text-red-900' aria-hidden='true' />
);


export const AI_CALENDER = (
  <AiOutlineCalendar className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const FA_TEACHER = (
  <FaChalkboardTeacher className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const GR_DOCUSER = (
  <GrDocumentUser className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const BI_IMAGE = (
  <BiImageAdd className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const BI_UPLOAD = (
  <BiUpload className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const AI_CONTACTS = (
  <AiOutlineContacts className='h-5 w-5 text-red-900' aria-hidden='true' />
);
export const MD_EDUCATION = (
  <MdOutlineCastForEducation
    className='h-5 w-5 text-red-900'
    aria-hidden='true'
  />
);

export const TB_MONEY = (
  <TbReportMoney className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const GI_TEACHER = (
  <GiTeacher className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const HI_OUTLINE_LOCATION_MARKER = (
  <HiOutlineLocationMarker
    className='h-5 w-5 text-red-900'
    aria-hidden='true'
  />
);
export const LEAVE = (
  <FcLeave className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const AI_OUTLINE_USER = (
  <AiOutlineUser className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const AI_OUTLINE_MOBILE = (
  <AiOutlineMobile className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const AI_OUTLINE_PHONE = (
  <AiOutlinePhone className='h-5 w-5 text-red-900' aria-hidden='true' />
);

export const XI_CIRCLE = (
  <AiFillCloseCircle className='h-5 w-5 text-red-900' aria-hidden='true' />
);
export const ACTIVE_CIRCLE = (
  <AiFillCheckCircle className='h-5 w-5 text-green-900' aria-hidden='true' />
);
