import { React } from 'react';
import { Tab as TabComponent } from '@headlessui/react';

function Tab({ menus }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div>
      <TabComponent.Group>
        <TabComponent.List className='flex space-x-1 rounded-xl bg-red-100/20 p-1 '>
          {menus?.map((menu, id) => (
            <TabComponent
              key={id}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm justify-center flex font-medium text-black',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-pink-700 focus:outline-none focus:ring-2',
                  selected ? 'bg-white shadow' : ' hover:bg-white/[0.12]'
                )
              }
            >
              <div className='flex items-center'>
                <div className='mr-[5px]'>{menu.tabIcon}</div>
                <div className='font-bold tracking-widest text-xs text-[#023047]'>
                  {menu.tabName}
                </div>
              </div>
            </TabComponent>
          ))}
        </TabComponent.List>
        <TabComponent.Panels className='mt-2'>
          {menus?.map((item, id) => (
            <TabComponent.Panel
              key={id}
              className={classNames(
                'rounded-xl bg-white p-1',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
              {item.tabContent}
            </TabComponent.Panel>
          ))}
        </TabComponent.Panels>
      </TabComponent.Group>
    </div>
  );
}

export default Tab;
