import React from 'react';
import SelectComponent from 'react-select';

function Select({
  name,
  label,
  values,
  placeholder,
  options,
  onChange,
  getOptionLabel,
  getOptionValue,
  disabled,
}) {
  return (
    <>
      <label
        htmlFor={name}
        className='block text-[11px] font-bold text-sky-900'
      >
        {label}
      </label>

      <SelectComponent
        isClearable
        isSearchable
        placeholder={placeholder}
        options={options}
        defaultValue={values}
        name={name}
        onChange={onChange}
        getOptionLabel={(option) => option[getOptionLabel]}
        getOptionValue={(option) => option[getOptionValue]}
        isDisabled={disabled}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,

          colors: {
            ...theme.colors,

            text: '#0F1A2D',
            primary25: '#E5E7EB',
            primary50: '#E5E7EB',

            primary: '#111B30',
          },
        })}
        className={`text-xs `}
        styles={{
          control: () => ({
            display: 'flex',
            backgroundColor: '#F3F3F3',
            borderRadius: '4px',
          }),
        }}
      />
    </>
  );
}

export default Select;
