import * as React from 'react';

export default function Badge({ status }) {
  const checkStatus = (status) => {
    if (status === 'approved') {
      return {
        color: 'bg-[#c8e6c9] text-[#256029]',
        name: 'Approved',
      };
    } else if (status === 'cancelled') {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'Cancelled',
      };
    } else if (status === 'rejected') {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'Rejected',
      };
    } else if (status === 'notrejected') {
      return {
        color: 'bg-orange-200 text-orange-900',
        name: 'Not Rejected',
      };
    } else if (status === true) {
      return {
        color: 'bg-[#c8e6c9] text-[#256029]',
        name: 'Active',
      };
    } else if (status === false) {
      return {
        color: 'bg-gray-200 text-gray-900',
        name: 'Archived',
      };
    } else if (status === 'head') {
      return {
        color: 'bg-[#ffcdd2] text-[#c63737]',
        name: 'Head',
      };
    } else if (status === 'sjc') {
      return {
        color: 'bg-[#ffcdd2] text-[#c63737]',
        name: 'jaffna',
      };
    } else if (status === 'organization') {
      return {
        color: 'bg-orange-200 text-orange-900',
        name: 'Organization',
      };
    } else if (status === 'section') {
      return {
        color: 'bg-[#feedaf] text-[#8a5340]',
        name: 'Section',
      };
    } else if (status === 'sent') {
      return {
        color: 'bg-[#c8e6c9] text-[#256029]',
        name: 'SENT',
      };
    } else if (status === 'notsent') {
      return {
        color: 'bg-[#ffcdd2] text-[#c63737]',
        name: 'Not Sent',
      };
    } else if (status === 'pending') {
      return {
        color: 'bg-yellow-100 text-yellow-900',
        name: 'Pending',
      };
    } else if (status === 'planned') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'Planned',
      };
    } else if (status === 'unplanned') {
      return {
        color: 'bg-yellow-100 text-yellow-900',
        name: 'Unplanned',
      };
    } else if (status === 'In') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'In',
      };
    } else if (status === 'Out') {
      return {
        color: 'bg-[#ffcdd2] text-[#c63737]',
        name: 'Out',
      };
    } else if (status === 'complete') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'Completed',
      };
    } else if (status === 'incomplete') {
      return {
        color: 'bg-[#ffcdd2] text-[#c63737]',
        name: 'InComplete',
      };
    }
  };

  let statusInfo = checkStatus(status);

  return (
    <React.Fragment>
      <span
        className={`uppercase text-[10px] tracking-widest font-bold px-1 py-1 rounded-sm ${statusInfo?.color}`}
      >
        {statusInfo?.name}
      </span>
    </React.Fragment>
  );
}
